/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
 Box, Drawer, Hidden, List, ListSubheader,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BusinessIcon from '@mui/icons-material/Business';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import PolicyIcon from '@mui/icons-material/Policy';
import {
  AlertOctagon as AlertOctagonIcon,
  Calendar as CalendarIcon,
  Clipboard as ClipboardIcon,
  FilePlus as FilePlusIcon,
  FileText as FileTextIcon,
  List as ListIcon,
  Server as ServerIcon,
  Users as UserIcon,
  Users as UsersIcon,
  // Layers as LayerIcon
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import screenRoutes from 'src/config/screen.routes';
import { includesAny } from 'src/constants';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Management',
    roleAccess: [
      'SuperUser',
      'Admin',
      'LoanServicer',
      'CustomerService',
      'AccountingDept',
    ],
    items: [
      {
        title: 'dashboard',
        roleAccess: ['SuperUser', 'Admin'],
        icon: DashboardIcon,
        href: `${screenRoutes.management.dashboard}`,
      },
      {
        title: 'portfolioLoans',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        icon: ServerIcon,
        items: [
          {
            title: 'searchLoans',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.loans.list}`,
          },
          {
            title: 'portfolioBalance',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.portfolioBalance}`,
          },

          /*
           * {
           *   title: 'portfolioPerformance',
           *   roleAccess: [
           *     'SuperUser',
           *     'Admin',
           *     'LoanServicer',
           *     'CustomerService',
           *     'AccountingDept',
           *   ],
           *   href: `${screenRoutes.portfolioPerformance.index}`,
           * },
           */
          {
            title: 'activeLoans',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.activeLoans}`,
          },

          /*
           * {
           *   title: 'watchList',
           *   roleAccess: [
           *     'SuperUser',
           *     'Admin',
           *     'LoanServicer',
           *     'CustomerService',
           *     'AccountingDept'
           *   ],
           *   href: `${screenRoutes.reports.watchList}`
           * },
           */
          {
            title: 'defaultedLoansReport',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.defaultedLoansReport}`,
          },
          {
            title: 'delinquency',
            roleAccess: ['LoanServicer'],
            href: `${screenRoutes.reports.delinquency}`,
          },
          {
            title: 'eQLoanListReport',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.eQLoanListReport}`,
          },
          {
            title: 'watchList',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
            ],
            href: `${screenRoutes.loanManagementFilters.management.list}`,
          },
          {
            title: 'collateralReport',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.collateralReport}`,
          },

          /*
           * {
           *   title: 'insuranceReport',
           *   roleAccess: [
           *     'SuperUser',
           *     'Admin',
           *     'LoanServicer',
           *     'CustomerService',
           *     'AccountingDept',
           *   ],
           *   href: `${screenRoutes.reports.insuranceReport}`,
           * },
           */
          {
            title: 'historicalDataTape',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.historicalDataTape}`,
          },
        ],
      },

      /*
       *       {
       *         title: 'Request',
       *         roleAccess: ['SuperUser',
       * 'Admin',
       * 'LoanServicer',
       * 'CustomerService'],
       *         icon: FilePlusIcon,
       *         href: `${screenRoutes.loanRequests.list}`,
       *       },
       */
      /*
       * {
       *   title: 'Loans',
       *   roleAccess: [
       *     'SuperUser',
       *     'Admin',
       *     'LoanServicer',
       *     'CustomerService',
       *     'AccountingDept'
       *   ],
       *   icon: CreditCardIcon,
       *   href: `${screenRoutes.loans.list}`
       * },
       */
      {
        title: 'riskManagement',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: AssignmentLateIcon,
        items: [
          {
            title: 'reports',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
            ],
            href: `${screenRoutes.riskManagement.reports}`,
          },
          {
            title: 'filters',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
            ],
            href: `${screenRoutes.riskManagement.filters}`,
          },

          /*
           * {
           *   title: 'create',
           *   roleAccess: [
           *     'SuperUser',
           *     'Admin',
           *     'LoanServicer',
           *     'CustomerService'
           *   ],
           *   href: `${screenRoutes.riskManagement.create}`
           * }
           */
        ],
      },
      {
        title: 'insurance',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: PolicyIcon,
        items: [
          {
            title: 'policies',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.insurance.policies}`,
          },
          {
            title: 'claims',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.insurance.claims}`,
          },
          {
            title: 'carrier',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.insurance.carrier}`,
          },
        ],
      },
      {
        title: 'customers',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: UsersIcon,
        href: `${screenRoutes.customers.list}`,
      },
      {
        title: 'tasks',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: CalendarIcon,
        href: `${screenRoutes.tasks.list}`,
      },
    ],
  },
  {
    subheader: 'ai',
    roleAccess: ['SuperUser',
'Admin',
'AccountingDept'],
    items: [
      {
        title: 'gemini',
        roleAccess: ['SuperUser',
'Admin',
'AccountingDept'],
        icon: AutoAwesomeIcon,
        href: `${screenRoutes.ai.gemini}`,
      },
    ],
  },
  {
    subheader: 'accounting',
    roleAccess: ['SuperUser',
'Admin',
'AccountingDept'],
    items: [
      {
        title: 'dashboard',
        roleAccess: ['SuperUser',
'Admin',
'AccountingDept'],
        icon: DashboardIcon,
        href: `${screenRoutes.accounting.dashboard}`,
      },
      {
        title: 'reports',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        icon: FileTextIcon,
        href: `${screenRoutes.reports.list}`,
        items: [

          /*
           * {
           *   title: 'general',
           *   href: `${screenRoutes.reports.general}`
           * },
           */
          {
            title: 'accountsReceivableAging',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.loansAccountReceivableAgingReport}`,
          },

          /*
           * {
           *   title: 'dailyPayments',
           *   roleAccess: [
           *     'SuperUser',
           *     'Admin',
           *     'LoanServicer',
           *     'CustomerService',
           *     'AccountingDept',
           *   ],
           *   href: `${screenRoutes.reports.dailyPayments}`,
           * },
           */
          /*
           * {
           *   title: 'delinquency',
           *   roleAccess: [
           *     'SuperUser',
           *     'Admin',
           *     'LoanServicer',
           *     'CustomerService',
           *     'AccountingDept'
           *   ],
           *   href: `${screenRoutes.reports.delinquency}`
           * },
           */
          {
            title: 'loansReceivable',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
              'AccountingDept',
            ],
            href: `${screenRoutes.reports.loansReceivableReport}`,
          },
        ],
      },
    ],
  },
  {
    subheader: 'Configuration',
    roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
    items: [

      /*
       * {
       *   title: 'originators',
       *   icon: StoreIcon,
       *   href: '/app/configuration/originators'
       * },
       */

      {
        title: 'Products',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: WorkOutlineOutlinedIcon,
        href: `${screenRoutes.loanProducts.list}`,
      },
      {
        title: 'access',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: ControlPointIcon,
        href: `${screenRoutes.access.users.list}`,
        items: [
          {
            title: 'users',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
            ],
            href: `${screenRoutes.access.users.list}`,
            icon: UserIcon,
          },

          /*
           * {
           *   title: 'roles',
           *   href: `${screenRoutes.access.roles}`,
           *   icon: LayerIcon
           * }
           */
        ],
      },
      {
        title: 'loanManagementFilters',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: ClipboardIcon,
        href: `${screenRoutes.loanManagementFilters.factorRules.list}`,
        // items: [

        //   /*
        //    * {
        //    *   title: 'manage',
        //    *   roleAccess: [
        //    *     'SuperUser',
        //    *     'Admin',
        //    *     'LoanServicer',
        //    *     'CustomerService',
        //    *   ],
        //    *   href: `${screenRoutes.loanManagementFilters.management.list}`,
        //    * },
        //    */
        //   {
        //     title: 'filters',
        //     roleAccess: [
        //       'SuperUser',
        //       'Admin',
        //       'LoanServicer',
        //       'CustomerService',
        //     ],
        //     href: `${screenRoutes.loanManagementFilters.factorRules.list}`,
        //   },
        // ],
      },
      {
        title: 'campaigns',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: MailOutlineOutlinedIcon,
        href: `${screenRoutes.campaigns.list}`,
        items: [
          {
            title: 'rules',
            roleAccess: [
              'SuperUser',
              'Admin',
              'LoanServicer',
              'CustomerService',
            ],
            href: `${screenRoutes.campaigns.list}`,
          },
        ],
      },
      {
        title: 'Organization',
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        icon: BusinessIcon,
        href: `${screenRoutes.organization.settings}`,
      },
    ],
  },
];

function renderNavItems({ items, roles, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
            acc,
            item,
            roles,
            ...rest,
          }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
 acc, pathname, item, roles, depth = 0,
}) {
  const key = item.title + depth;
  const hasAccess = item.roleAccess ? includesAny(item.roleAccess, roles) : false;

  if (!hasAccess) {
    acc.push(<div />);
  } else if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem
        key={key}
        depth={depth}
        icon={item.icon}
        info={item.info}
        open={Boolean(open)}
        title={i18n.t(item.title)}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          roles,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        key={key}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        pathName={pathname}
        title={i18n.t(item.title)}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  menuSiteBar: {
    backgroundColor: theme.palette.secondary.main,
  },
  logoColor: {
    backgroundColor: theme.palette.secondary.logo,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      className={classes.menuSiteBar}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box display="flex" justifyContent="center" />
        </Hidden>
        {/* <Box
          className={classes.logoColor}
          display="flex"
          justifyContent="center"
          p={2}
        >
          <RouterLink to="/app/management/loans">
            <Logo />
          </RouterLink>
        </Box> */}
        <Box p={2}>
          {navConfig.map((config) => {
            const hasAccess = config.roleAccess ? includesAny(config.roleAccess, user?.roles ?? []) : false;

            if (!hasAccess) {
              return <></>;
            }

            return (
              <List
                key={config.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                    style={{ color: '#FFF' }}
                  >
                    {t(config.subheader)}
                  </ListSubheader>
                )}
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname,
                  roles: user?.roles ?? [],
                })}
              </List>
            );
          })}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
